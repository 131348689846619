import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { BillboardPhotoControlInfo } from '../../RemoteCommands/type';
import { Settings } from '../../utils/settings'

import Fancybox from '../../hooks/FancyboxHook';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';

type Props = ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> 
    & {
    billboardsPhotocontrol: BillboardPhotoControlInfo[];
};

const PhotocontrolCardTable = (props: Props) => {
    const { 
        billboardsPhotocontrol,
        userKey
    } = props;

    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        className: "mainSlider mainCarousel"
    };

    const fancyOptions = {
        infinite: false,
        placeFocusBack: false, 
        Toolbar: {
            display: ['counter', 'zoom', 'fullscreen' , 'download', 'thumbs', 'close']
        }
    }

    return (
        <TableContainer component={Paper} style={{ maxHeight: 700 }}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>Название</TableCell>
                        <TableCell align="center">GID</TableCell>
                        <TableCell align="center">Регион</TableCell>
                        <TableCell align='center'>Фотоконтроллер</TableCell>
                        <TableCell align='center'>Фотографии</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {billboardsPhotocontrol.map((item, index) => {
                        return (
                            <TableRow key={'photocontrol-' + item.geoMarkId}>
                                <TableCell align='center'>{item.geoMarkName}</TableCell>
                                <TableCell align="center">{item.gid}</TableCell>
                                <TableCell align="center">{item.region}</TableCell>
                                <TableCell align='center'>{item.photoControllerName}</TableCell>
                                <TableCell align='center' style={{maxHeight: 100}}>
                                    {item.images &&
                                        <div className='fancyImages '> 
                                            <Fancybox options={fancyOptions}>
                                                <Slider {...settings}>
                                                    {item.images.map(image => {
                                                        const imgSrc = `${Settings.imgPhotoUrl + image.imageId}&sideName=`;
                                                        const imgSrcBig = `${Settings.imgPhotoUrl + image.imageId}&sideName=&bigPhoto=true`;
                                                        return (
                                                            <a 
                                                                key={`carousel-${image.imageId}`}
                                                                data-fancybox={`gallery-${item.geoMarkId}`} 
                                                                data-caption={moment(image.takingTime).format('DD.MM.YYYY')}
                                                                data-download-src={imgSrcBig} 
                                                                data-download-filename={`фотоконтроль-${item.geoMarkId}-${item.photoControllerName}-${image.imageId}-${moment(image.takingTime).format('DD.MM.YYYY-HH-mm-ss')}.jpg`}
                                                                href={imgSrcBig}
                                                                className="mainSlider__item"
                                                                >
                                                                <img src={imgSrc} alt="" />
                                                            </a>
                                                        )
                                                    })}
                                                </Slider>
                                            </Fancybox>
                                        </div>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    userKey: state.user.licenceKey,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotocontrolCardTable);